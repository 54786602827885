.resume_tamplate {
    display: block;
    /* max-width: 700px; */
    width: 700px;
    margin: 0 auto;
    padding: 0;
    font-family: Roboto;
    line-height: 1;
}

.resume_tamplate h1 {
    border: none;
    text-align: center;
    font-weight: bold;
    padding: 0;
    margin: 1rem 0;
}

.resume_tamplate h2 {
    font-size: 120%;
    font-weight: bold;
    border-bottom: 2px solid black;
    color: black;
    margin: 10px 0 0 0;
}

.resume_tamplate h3 {
    font-size: 100%;
    font-weight: bold;
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 0;
}

.single-userInfo .name {
    font-size: 150%;
    line-height: 150%;
    font-weight: bold;
    display: block;
    text-align: center;
}

.single-userInfo a {
    text-decoration: none;
    margin-left: 3px;
    margin-right: 1px;
}

.single-educationInfo {
    margin-bottom: 10px;
    line-height: 100%;
}

.education-detail {
    display: flex;
    justify-content: flex-start;
}

.education-detail .time {
    margin-left: 5px;
}

.education-detail .gpa {
    margin-left: auto;
    margin-right: 30px;
    font-weight: bold;
}

.section-skill {
    display: grid;
    grid-template: auto auto / 20% 1fr;
    line-height: 100%;
}

.relaventExpirence-info {
    display: flex;
    justify-content: flex-start;
    justify-items: start;
    flex-wrap: wrap;
}

.relaventExpirence-info .location {
    margin-left: 5px;
}

.relaventExpirence-info time {
    margin-left: auto;
}

.resume_tamplate p,
ul {
    font-size: 100%;
    margin: 0;
}

.resume_tamplate ul,
ol {
    padding: 0 0 0 1.5rem;
    margin: 0;
}

.resume_tamplate li {
    line-height: 100%;
    /* change this for tighter spacing */
    /* default 150% */
}

.resume_tamplate .info img {
    vertical-align: sub;
    width: 15px;
}

.resume_tamplate a {
    color: black;
}

.resume_tamplate code {
    color: black;
}

.resume_tamplate .location {
    font-weight: normal;
    font-style: italic;
}

.resume_tamplate .info {
    display: block;
    text-align: center;
}

.project-title {
    display: inline;
    margin-right: 10px;
}

.project-contents {
    display: inline;
    line-height: 100%;
}

.resume_paper {
    border: 1px solid black;
    height: 297mm;
    width: 210mm;
}