.resume_entry {
    max-height: 100%;
    overflow-y: auto;
    width: 100%;
}

.resume {
    height: 100vh;
}


/* #root {
    background-color: #1E1E1E;
} */